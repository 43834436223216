import React from 'react';
import { Stack } from '@cp/ds/src/components/stack';
import { MOBILE_BREAKPOINT_THRESHOLD } from '@cp/ds/src/theme';
import { ClientPhotoCard } from '@cp/entities/clientPhoto';
import { useIsMobile } from '@cp/ds/src/hooks/useIsMobile';
import { Typography } from '@cp/ds/src/components/typography';
import { useTheme } from '@cp/ds/src/hooks';
import { HeaderProps } from './model';

export * from './model';

const AVATAR_SIZE_MOBILE = 48;
const AVATAR_SIZE_DESKTOP = 43;

export const Header: React.FC<HeaderProps> = ({ clientProfile, onClickClientPhoto, sx }) => {
  const isMobile = useIsMobile();
  const theme = useTheme();

  return (
    <Stack alignItems="center" direction="row" sx={sx}>
      <ClientPhotoCard
        contentBackgroundColor={theme.palette['brand gray']}
        data={clientProfile}
        onClick={onClickClientPhoto}
        rounded
        size={isMobile ? AVATAR_SIZE_MOBILE : AVATAR_SIZE_DESKTOP}
        sx={{
          mr: { xs: 1.5, [MOBILE_BREAKPOINT_THRESHOLD]: 2 },
        }}
      />
      <Typography variant="h3">{clientProfile.name}</Typography>
    </Stack>
  );
};
